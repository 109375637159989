.Root-header {
  background-image: url('./Art1280x720.png');
  background-repeat: no-repeat;
  background-position: center;
  background-color: black;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 25px;
  font-size: calc(10px + 2vmin);
}
