/* src/index.css */
.mle-container {
  position: relative;
  font-family:
    Inter,
    system-ui,
    Avenir,
    Helvetica,
    Arial,
    sans-serif;
  font-size: 1rem;
}
.mle-btn-holder {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 1000;
  user-select: none;
}
.mle-btn {
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
  padding: 6px 16px;
  border-radius: 4px;
  border: none;
  font-size: 0.875rem;
  line-height: 1.75;
  font-family: inherit;
  background: #1976d2;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  transition: all 0.3s ease;
}
.mle-btn-icon {
  padding: 6px;
}
.mle-btn:focus,
.mle-btn:focus-visible,
.mle-btn:hover {
  background: #1565c0;
}
.mle-btn:disabled {
  cursor: default;
  pointer-events: none;
  background: #407db7;
  color: rgb(255 255 255 / 30%);
}
.mle-btn svg {
  font-size: 1.5rem;
  line-height: 1;
  width: 1em;
  height: 1em;
  color: currentColor;
  display: block;
}
